<template>
    <div class="Bet card">
        <div class="info">
            <div class="type">
                <h3>{{ bet.options.bets.length === 1 ? 'Single' : `Parlay (${ bet.options.bets.length } Picks)` }}</h3>
                <h4>{{ `${ getDate(bet.createdAt) }\n${ getTime(bet.createdAt) }` }}</h4>
            </div>
            <div class="status" :class="{ expanded: isExpanded }">
                <div class="row">
                    <h4>Status</h4>
                    <Status :type="bet.void ? 'void' : getTicketStatus(bet)" />
                </div>
                <div v-if="isExpanded">
                    <div class="row" v-if="!isTicket">
                        <h4>Book</h4>
                        <h4 class="detail">{{ books[bet.book].name }}</h4>
                    </div>
                    <div class="row" v-if="isTicket">
                        <h4>Placed By</h4>
                        <h4 class="detail">{{ ticketHolder.firstName }} {{ ticketHolder.lastName }}</h4>
                    </div>
                    <div class="row">
                        <h4>Stake</h4>
                        <h4 class="detail">${{ bet.options.risk.toFixed(2) }}</h4>
                    </div>
                    <div class="row">
                        <h4>{{ winHeader }}</h4>
                        <h4 class="detail">{{ bet.void ? 'Refund' : `${ getTicketStatus(bet) !== 'push' ? '$' : '' }` }}{{ bet.void ? '' : win }}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isExpanded">
            <div class="bets" v-for="b in bet.options.bets" :key="b.wager[0]">
                <div class="header">
                    <div class="name">
                        <h3>{{ b.wager[1].name }}</h3>
                        <p class="footnote">{{ b.wager[1].variant }}</p>
                        <p class="footnote course" v-if="b.wager[1].course">{{ b.wager[1].course }}</p>
                        <p class="footnote result" v-if="getBetStatus(b) !== 'pending'">{{ displayResult(b) }}</p>
                    </div>
                    <Status :type="bet.void ? 'void' : getBetStatus(b)" />
                </div>
                <div class="row">
                    <h4>{{ b.wager[1].lines[b.selected] }}</h4>
                    <h4 class="odds">{{ b.wager[1].odds[b.selected].toFixed(2) }}</h4>
                </div>
            </div>
            <div class="tally" v-if="bet.options.bets.length > 1">
                <div class="row">
                    <h4>Combined Odds</h4>
                    <h4 class="odds">{{ odds.toFixed(2) }}</h4>
                </div>
            </div>
        </div>
        <div class="void-wrapper" v-if="isExpanded && isTicket && !bet.void">
            <Button theme="red" @onClick="isVoiding = true">Void Ticket</Button>
            <Confirm v-if="isVoiding" okay="Yes" @okay="voidTicket(bet)" @cancel="isVoiding = false">Are you sure you want to void this ticket?</Confirm>
        </div>
        <div class="see-more" @click="isExpanded = !isExpanded">
            <h4>{{ buttonLabel }}</h4>
        </div>
    </div>
</template>

<script>

    // Components.
    import Status from '@/components/Status.vue';
    import Button from '@/components/Button.vue';
    import Confirm from '@/components/Confirm.vue';

    // Firebase.
    import { voidBet } from '@/firebase/wagers.js';

    // Misc.
    import { getDate, getTime, getTicketStatus, getBetStatus } from '@/utils';

    export default {
        name: 'Bet',
        components: {
            Status,
            Button,
            Confirm,
        },
        props: {
            bet: Object,
            wagers: Object,
            books: Object,
            isTicket: Boolean,
            ticketHolder: Object,
            id: String,
        },
        data() {
            return {
                isExpanded: false,
                isVoiding: false,
            }
        },
        computed: {
            buttonLabel() {
                if (this.isTicket) return this.isExpanded ? 'CLOSE' : 'MANAGE';
                return this.isExpanded ? 'SEE LESS' : 'SEE MORE';
            },
            odds() {

                let odds = 1;
                this.bet.options.bets.map(bet => { odds *= bet.wager[1].odds[bet.selected] });

                return odds;

            },
            adjustedOdds() {

                let odds = 1;

                this.bet.options.bets.map(bet => {
                    odds *= this.getBetStatus(bet) === 'push' ? 1 : bet.wager[1].odds[bet.selected];
                });

                return odds;

            },
            win() {

                const betStatus = this.getTicketStatus(this.bet);

                if (betStatus === 'pending') {
                    return ((this.bet.options.risk * this.odds) - this.bet.options.risk).toFixed(2);
                } else {
                    switch (betStatus) {
                        case 'loss':
                            return (0).toFixed(2);
                        case 'push':
                            return 'Refund';
                        case 'win':
                            return ((this.bet.options.risk * this.adjustedOdds) - this.bet.options.risk).toFixed(2);
                    }
                }

            },
            winHeader() {
                return this.getTicketStatus(this.bet) === 'pending' ? 'To Win' : 'Won';
            }
        },
        methods: {
            getDate(timestamp) {
                return getDate(timestamp);
            },
            getTime(timestamp) {
                return getTime(timestamp);
            },
            getTicketStatus(ticket) {
                return getTicketStatus(ticket, this.$props.wagers);
            },
            getBetStatus(bet) {
                return getBetStatus(bet, this.$props.wagers);
            },
            voidTicket(bet) {
                this.$store.dispatch('updateLoading', 1);
                voidBet(this.user.uid, bet.user, bet.book, this.id).then(() => {
                    this.isVoiding = false;
                    this.$store.dispatch('updateLoading', -1);
                });
            },
            displayResult(bet) {

                const wager = this.wagers[bet.wager[0]];
                let result = 'RESULT:';

                if (wager.type === 'overUnder') {
                    result += ` ${ wager.finalTotal }`;
                } else {
                    for (const line of wager.winningLines) {
                        result += ` ${ wager.lines[line] }`;
                    }
                }

                return result;

            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .Bet {
        margin-top: size(Small);
    }

    .info, .bets {
        padding: size(Medium);
    }

    .info .type,
    .row,
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .row {
        padding-top: size(Medium);
    }

    .status.expanded > .row {
        margin-bottom: -5px;
    }

    .info .type {
        border-bottom: 1px solid color(Emperor, 0.15);
        padding-bottom: size(Medium);
    }

    .info .type h4 {

        white-space: pre;
        text-align: right;
        line-height: 1.25;

        opacity: 0.25;

    }

    .see-more {

        display: flex;
        align-items: center;
        justify-content: center;

        padding: size(Medium);

        border-top: 1px dashed color(Emperor, 0.15);

        color: color(OldGold);

    }

    .detail {
        opacity: 0.25;
    }

    .header {
        padding-bottom: size(Medium);
        border-bottom: 1px solid color(Emperor, 0.15);
    }

    .header p.footnote {
        font-weight: 700;
        // margin-top: 2px;
    }

    .header p.footnote:first-of-type {
        margin-top: size(Micro);
    }

    .header p.footnote.result {
        margin-top: size(Small);
    }

    .odds {
        color: color(OldGold);
    }

    .bets, .void-wrapper, .tally {
        border-top: 1px dashed color(Emperor, 0.15);
    }

    .void-wrapper {
        padding: size(Medium) size(Large);
    }

    .tally {
        padding: 0 size(Medium) size(Medium);
    }

</style>
