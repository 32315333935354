<template>
    <div class="Status bumper" :class="type">
        <h4>{{ type }}</h4>
    </div>
</template>

<script>

    export default {
        name: 'Status',
        props: {
            type: { type: String, default: 'pending', validator: value => {
                return ['pending', 'open', 'closed', 'suspended', 'settled', 'win', 'loss', 'push', 'void'].indexOf(value) !== -1;
            }},
        },
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .Status {

        display: inline-block;

        padding: size(Micro);
        border-radius: 5px;

        text-transform: uppercase;

    }

    .Status.open,
    .Status.pending,
    .Status.push {
        background-color: color(WildSand);
        color: color(Emperor, 0.25);
    }

    .Status.closed,
    .Status.suspended,
    .Status.loss,
    .Status.void {
        background-color: color(Carmine);
        color: color(White, 1);
    }

    .Status.settled,
    .Status.win {
        background-color: color(OldGold);
        color: color(White, 1);
    }

</style>
