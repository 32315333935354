<template>
    <section id="OpenBets">
        <h3 class="no-results" v-if="filteredBets.length === 0">No results available.</h3>
        <Bet v-for="bet in filteredBets" :key="bet.createdAt" :bet="bet" :wagers="wagers" :books="books" />
        <Options :options="options" :selected="0" />
    </section>
</template>

<script>

    // Components.
    import Options from '@/components/Options.vue';
    import Bet from '@/components/Bet.vue';

    // Firebase.
    import { getBet, getWager } from '@/firebase/wagers.js';
    import { getBook } from '@/firebase/books.js';

    // Misc.
    import { BETS_OPTIONS } from '@/constants.js';

    export default {
        name: 'OpenBets',
        components: {
            Options,
            Bet,
        },
        data() {
            return {
                bets: [],
                wagers: {},
                books: {},
            }
        },
        computed: {
            options() {
                return BETS_OPTIONS;
            },
            filteredBets() {
                return this.bets.filter(bet => {

                    let isOpen = false;

                    for (const b of bet.options.bets) {
                        if (this.wagers[b.wager[0]].status === 'open') {
                            isOpen = true;
                            break;
                        }
                    }

                    return isOpen && !bet.void;

                }).sort((a, b) => b.createdAt - a.createdAt);
            }
        },
        mounted() {
            this.$store.dispatch('setNavShadow', false);
        },
        created() {
            if (this.profile.bets) {

                for (let bet of Object.keys(this.profile.bets)) {

                    this.$store.dispatch('updateLoading', 1);

                    getBet(bet).then(async snapshot => {

                        const val = snapshot.val();

                        for (let b of val.options.bets) {
                            if (!this.wagers[b.wager[0]]) {
                                this.wagers[b.wager[0]] = (await getWager(b.wager[0])).val();
                            }
                        }

                        this.books[val.book] = (await getBook(val.book)).val();
                        this.bets.push(val);
                        this.$store.dispatch('updateLoading', -1);

                    });

                }
            }
        },
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    #OpenBets {
        padding: size(Medium) 0 50px + size(Medium);
    }

    .no-results {
        padding: 0 size(Medium);
        text-align: center;
    }

    .Bet:first-of-type {
        margin-top: 0;
    }

</style>
